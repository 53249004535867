import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

const SubmissionCard = (props) => {
  const { date, title, numberOfFiles, totalRows, selectedRows, submitEnabled, submitCard, setTitle } = props;

  const [editing, setEditing] = useState(false);

  const renderSelectedRows = () => {
    if (isNaN(selectedRows)) {
      return <div className="t--sm px-20">Selected Rows: --</div>;
    }
    return (
      <div className="t--sm px-20">
        Selected Rows: {selectedRows}/{totalRows}
      </div>
    );
  };

  return (
    <div className="tile" style={{ position: "relative" }}>
      <div className="tile__header">
        <div className="draw__submission_card__date_icon">
          <div className="draw__submission_card__date_icon__date">{moment(date).format("DD")}</div>
          <div className="draw__submission_card__date_icon__month">{moment(date).format("MMM")}</div>
        </div>

        <div>
          {editing ? (
            <div className="flex" style={{ gap: 10 }}>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="tile__input"
                style={{ marginLeft: 10 }}
                autoFocus
                data-testid="title-field"
              />

              <button className="button button--icon" onClick={() => setEditing(false)}>
                <div className="material-symbols-outlined">save</div>
              </button>
            </div>
          ) : (
            <>
              <div
                className="flex__container"
                style={{
                  maxWidth: 120,
                  overflowWrap: "break-word",
                  margin: "0 10px",
                  flexDirection: "column",
                }}
              >
                <div className="t--sm t--subtitle t--italics">Submission Name</div>
                <div className="t--sm t--bold">{title}</div>
              </div>
              <div style={{ position: "absolute", top: 10, right: 10 }}>
                <button className="button button--icon" onClick={() => setEditing(true)}>
                  <div className="material-symbols-outlined">edit</div>
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="item--flex my-10 t--gray">
        <div className="t--sm">Files: {numberOfFiles}</div>
        {renderSelectedRows()}
      </div>

      <div className="item--flex flex--between">
        <a href={window.location.href}>
          <div className="button button--icon">
            <div className="material-symbols-outlined">delete</div>
          </div>
        </a>
        <button
          className={`button button--fw ${submitEnabled ? "" : "btn--disabled"}`}
          disabled={!submitEnabled}
          onClick={submitCard}
          data-testid="submit-button"
        >
          Save and Submit
        </button>
      </div>
    </div>
  );
};

export default SubmissionCard;

import React, { useMemo } from "react";
import { AreaChart, Area, ResponsiveContainer } from "recharts";

const placeholderData = [
  { y: 10 },
  { y: 11 },
  { y: 12 },
  { y: 15 },
  { y: 15 },
  { y: 14 },
  { y: 15 },
  { y: 16 },
  { y: 17 },
  { y: 15 },
  { y: 14 },
  { y: 17 },
  { y: 18 },
  { y: 20 },
  { y: 21 },
  { y: 22 },
  { y: 25 },
  { y: 22 },
  { y: 20 },
  { y: 21 },
  { y: 20 },
  { y: 21 },
  { y: 21 },
  { y: 22 },
  { y: 23 },
  { y: 21 },
  { y: 21 },
  { y: 24 },
  { y: 23 },
  { y: 25 },
  { y: 26 },
  { y: 27 },
  { y: 25 },
  { y: 25 },
  { y: 28 },
  { y: 29 },
  { y: 30 },
];

const Credits = ({ data }) => {
  const credits = !data ? placeholderData : data.map((credit) => {
    return {
      y: credit.running_total,
    };
  });

  return (
    <ResponsiveContainer width="100%" height={120}>
      <AreaChart
        data={credits}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="20%" stopColor={data ? "#0560FD" : "#DEE7EE"} />
            <stop offset="90%" stopColor="#FFFFFF" />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="y"
          x
          strokeWidth={2}
          stroke={data ? "#0560FD" : "#DEE7EE"} 
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Credits;

import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/components/shared/table";
import { IssuesToolTip } from "/components/shared/IssuesTooltip";
import _ from "lodash";

function InvoiceLinesTable({ data, isLoading, searchQuery, setSearchQuery, setNotice, handleRowClick, selectedRowIndex, getIndicatorColor}) {
  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("invoice_number", {
        header: "Invoice Number",
        sorting: false,
        cell: (info) => <div className="t--fw-500">{info.getValue()}</div>,
      }),
      columnHelper.accessor("invoice_date", {
        header: "Invoice Date",
        cell: (info) => info.getValue(),
        filterFn: "equalsString",
        sorting: false,
      }),
      columnHelper.accessor("ndc", {
        header: "NDC",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("id_340b", {
        header: "340b ID",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("service_provider", {
        header: "Ship-To",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("filled_percentage", {
        header: "Filled",
        cell: (info) => 
          info.row.original.package_quantity > 0 ?
          <div>{`${info.row.original.filled_packages} of ${info.row.original.package_quantity} `}<span className="t--light">{`(${info.getValue()})`}</span></div> :
          <div>N/A</div>
        ,
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => 
          <div className="badge">
            <div className={`badge__indicator badge__indicator--${getIndicatorColor(info.getValue())}`} />
            {info.getValue()}
          </div>,
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("package_quantity", {
        header: "Qty",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("matched_packages", {
        header: "Matched",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor((row) => row.issues, {
        header: "Issues",
        enableSorting: false,
        cell: ({ row, getValue }) => {
          if (_.size(getValue()) > 0) {
            return <IssuesToolTip row={row} getValue={getValue} />;
          }
          return "--";
        },
      }),
      columnHelper.accessor("submission_date", {
        header: "Submission Date",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
    ];
  });

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        //filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
      />
    </div>
  );
}

export default InvoiceLinesTable;

import _ from "lodash";

export const columnSchema = {
  date_of_service: null,
  date_prescribed: null,
  rx_number: null,
  ndc: null,
  quantity: null,
  prescriber_id: null,
  service_provider_id: null,
  contracted_entity_id: null,
  fill_number: null,
  rx_bin_number: null,
  rx_pcn_number: null,
  reimbursement_amount: null,
};

export const columnDetails = [
  {
    name: "date_of_service",
    title: "Date of Service",
    description: "Date on which the patient filled their prescription.",
    required: true,
    order: 99,
  },
  {
    name: "date_prescribed",
    title: "Date Prescribed",
    description: "Date the prescriber wrote the prescription.",
    required: true,
    order: 99,
  },
  {
    name: "rx_number",
    title: "Rx Number",
    description:
      "The native (unmodified) prescription number for the prescription as generated by the pharmacy.",
    required: true,
    order: 99,
  },
  {
    name: "ndc",
    title: "NDC-11",
    description:
      "The 11-digit National Drug Code which indicates the manufacturer, product, and the commercial package size.",
    required: true,
    order: 99,
  },
  {
    name: "quantity",
    title: "Quantity",
    description: "The number of units in the prescription.",
    required: true,
    order: 99,
  },
  {
    name: "service_provider_id",
    title: "Service Provider ID",
    description:
      "A unique, public ID for the dispensing pharmacy. Accepted IDs include the NPI, DEA, NCPDP, and Medicaid ID.",
    required: true,
    order: 99,
  },
  {
    name: "contracted_entity_id",
    title: "340B ID",
    description:
      "The 340B ID of the covered entity where the prescription originated. This is usually the 340B ID of the covered entity under which you registered.",
    required: true,
    order: 99,
  },
  {
    name: "prescriber_id",
    title: "Prescriber ID",
    description:
      "A unique, public ID for the prescribing physician. Accepted IDs include the NPI and DEA ID.",
    required: true,
    order: 99,
  },
  {
    name: "fill_number",
    title: "Fill Number",
    description:
      "Indicates the number of times a prescription has been refilled.  A value of 2 would mean the current prescription claim is for the second refill of the prescription.",
    required: true,
    order: 99,
  },
  {
    name: "rx_bin_number",
    title: "BIN Number",
    description:
      "The bank identification number of the primary payer on the prescription. This field may contain the value 'CASH' for prescriptions paid for out of pocket by the patient.",
    required: true,
    order: 99,
  },
  {
    name: "rx_pcn_number",
    title: "PCN Number",
    description:
      "Processor Control Number. Identifier used to determine which processor will handle a prescription drug claim. This field may contain the value 'CASH' for prescriptions paid for out of pocket by the patient.",
    required: true,
    order: 99,
  },
  // {
  //   name: "reimbursement_amount",
  //   title: "Reimbursement Amount",
  //   description:
  //     "The amount reimbursed by a payer.",
  //   required: false,
  //   order: 99,
  // },
];

export const tableHeaders = columnDetails.map((column) => column.name);
export const sortedColumnDetails = columnDetails.sort(
  (a, b) => a.order - b.order
);
export const required = columnDetails
  .filter((column) => column.required)
  .map((column) => column.name);
export const notRequired = columnDetails
  .filter((column) => !column.required)
  .map((column) => column.name);
export const isRequiredField = (column) => {
  return required.includes(column);
};

export class PharmacyClaim {
  constructor() {
    this.hasErrors = false;

    this.data = {
      date_of_service: null,
      date_prescribed: null,
      rx_number: null,
      ndc: null,
      quantity: null,
      prescriber_id: null,
      service_provider_id: null,
      contracted_entity_id: null,
      fill_number: null,
      rx_bin_number: null,
      rx_pcn_number: null,
      reimbursement_amount: null,
      formatted_rx_number: null,
    };

    this.columns_with_errors = {
      date_of_service: false,
      date_prescribed: false,
      rx_number: false,
      ndc: false,
      quantity: false,
      prescriber_id: false,
      service_provider_id: false,
      contracted_entity_id: false,
      fill_number: false,
      rx_bin_number: false,
      rx_pcn_number: false,
      reimbursement_amount: false,
    };
  }
}

import React, { useState, useEffect } from "react";
import Filters from "/components/shared/filters";
import Http from "../http";

const account_data = [
  {
    id: 1,
    total: "$75,000.00",
    bank: "JP Morgan Chase",
    id_340b: "DSH12345",
    account_number: 123456789,
    payments: [
      {
        account_number: 123456789,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Paid",
      },
      {
        account_number: 123456789,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Pending Payment",
      },
      {
        account_number: 123456789,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Pending Payment",
      },
      {
        account_number: 123456789,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Paid",
      },
      {
        account_number: 123456789,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Pending Payment",
      },
      {
        account_number: 123456789,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Pending Payment",
      },
      {
        account_number: 123456789,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Pending Payment",
      },
      {
        account_number: 123456789,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Paid",
      },
    ],
  },
  {
    id: 2,
    total: "$22,000.00",
    bank: "JP Morgan Chase",
    id_340b: "DSH12345",
    account_number: 123456888,
    payments: [
      {
        account_number: 123456888,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Paid",
      },
      {
        account_number: 123456888,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Pending Payment",
      },
      {
        account_number: 123456888,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Pending Payment",
      },
      {
        account_number: 123456888,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Paid",
      },
      {
        account_number: 123456888,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Pending Payment",
      },
      {
        account_number: 123456888,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Pending Payment",
      },
      {
        account_number: 123456888,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Pending Payment",
      },
      {
        account_number: 123456888,
        amount: "$1,880.00",
        date: "2024-06-21",
        status: "Paid",
      },
    ],
  },
];

const PurchaseReport = (props) => {
  const [selected, setSelected] = useState(null);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const [filters, setFilters] = useState();
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    Http.newFetchQuery(props.query_path, filters).then((res) => {
      setInvoices(res.purchases);
      setIsLoading(false);
    });
  }, [filters]);

  const renderCards = () => {
    return account_data.map((account, index) => {
      return (
        <Card
          key={index}
          header={account.id_340b}
          subtitle={account.bank}
          title={account.total}
          footer={account.account_number}
          selected={account.id == selected?.id}
          onClick={() => setSelected(account)}
        />
      );
    });
  };

  const renderAccount = () => {
    if (selected == null) {
      return <h3>Please select an account</h3>;
    }
    return (
      <>
        <h3>Account Details</h3>
        <h1>{selected.total}</h1>
        <h3>Packages</h3>
        <p>January 2024 - December 2024</p>
        <table className="report__table">
          <tr>
            <th>Account Number</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Status</th>
            <th></th>
          </tr>
          {selected.payments.map((payment, index) => {
            return (
              <tr className="report__table--row" key={index}>
                <td>{payment.account_number}</td>
                <td>{payment.amount}</td>
                <td>{payment.date}</td>
                <td>{payment.status}</td>
              </tr>
            );
          })}
        </table>
      </>
    );
  };

  return (
    <>
      <div className="body__head">
        <div className="body__head__title">Accounts Home</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Filters filterCallback={setFilters} defaultFilters={props.filters} filterLists={props.filterLists} />
        </div>
      </div>
      <div className="body__content">
        <div className="panel--menu">{renderCards()}</div>
        <div className="panel">{renderAccount()}</div>
        <div className="panel--details">
          <h3>Payment Details</h3>
        </div>
      </div>
    </>
  );
};

const Card = ({ header, subtitle, title, footer, selected, onClick }) => {
  return (
    <div onClick={onClick} className={selected ? "card card--selected" : "card"}>
      <div className="card__header">{header}</div>
      <div className="card__subtitle">{subtitle}</div>
      <div className="card__title">{title}</div>
      <div className="card__footer">{footer}</div>
    </div>
  );
};

export default PurchaseReport;

import _ from "lodash";
import { PharmacyClaim, tableHeaders } from "../../schemas/pharmacy_claim";

import { formatDate, normalizeRxNumber, generateSecureHash, formatEntityId } from "../../utils/data_helpers";
import { isDateInRange } from "../../validators/data_validations";
import { OriginalData } from "../../schemas/original_data";
import isValidPharmacyClaimField from "../../validators/is_valid_pharmacy_claim_field";

/* 
PURPOSE:
1. Filter out rows that don't match filterlist (ndcs, etc.)
2. Normalize data to conform to schema (adds 2 custom columns)
3. Validate Data (valid cell values, valid column mappings, etc.)
4. Format necessary values (format dates, remove dash from rx numbers)
5. Hash/de-itentify necessary cells
  */

const PharmacyClaimsEngine = ({ data, mapping, filterLists, salt }) => {
  var noMappingError = false;
  var hasErrors = false;
  var mappingMismatch = false;
  var columnsWithErrors = [];
  var result = { originalData: [], formattedData: [] };

  const process = () => {
    // skip data validation if there are no mappings
    if (!mapping) {
      _.each(data, (row) => {
        result.originalData.push(setOriginal(row, mapping, false));
      });

      noMappingError = true;
      hasErrors = true;

      return result;
    }

    _.forIn(mapping, (value, key) => {
      if (value && !(value in data[0])) {
        mappingMismatch = true;
      }
    });

    _.each(data, (row) => {
      if (inList(row)) {
        const { claim, originalData } = processRow(row, mapping);

        result.formattedData.push(claim);
        result.originalData.push(originalData);
        hasErrors = hasErrors || claim.hasErrors;
      } else {
        var originalData = setOriginal(row, mapping, false);
        result.originalData.push(originalData);
      }
    });

    return result;
  };

  const inList = (row) => {
    var isInList = true;

    filterLists.forEach((list) => {
      var value = row[mapping[list.filter_key]];
      if (list.filter_key == "contracted_entity_id") {
        value = formatEntityId(value);
      }
      if (!(value in list.filter_list)) {
        isInList = false;
      }
    });

    return isInList;
  };

  const processRow = (row, mapping) => {
    let claim = new PharmacyClaim();
    let originalData = setOriginal(row, mapping, inList(row));

    _.forIn(claim.data, (_, columnName) => {
      setClaimData(row, claim, columnName, mapping, salt);

      setValidationErrors(row, claim, originalData, columnName, mapping);
    });

    return {
      claim,
      originalData,
    };
  };

  const setOriginal = (row, mapping, selected) => {
    return new OriginalData(row, mapping, selected);
  };

  const setClaimData = (row, claim, columnName, mapping, salt) => {
    claim.data[columnName] = formatValue(row, columnName, row[mapping[columnName]], mapping, salt);
  };

  const setValidationErrors = (row, claim, originalData, columnName, mapping) => {
    var result = isValidPharmacyClaimField(row[mapping[columnName]], columnName, row, mapping);
    if (!result) {
      claim.columns_with_errors[columnName] = true;
      claim.hasErrors = true;
      originalData.columns_with_errors[columnName] = true;
      originalData.hasErrors = true;
      columnsWithErrors.push(columnName);
    }
  };

  const formatValue = (row, columnName, value, mapping, salt) => {
    if (columnName == "formatted_rx_number") {
      return generateSecureHash(normalizeRxNumber(row[mapping["rx_number"]]), salt);
    }

    if (columnName == "rx_number") {
      return generateSecureHash(value, salt);
    }

    if (["date_of_service", "date_prescribed"].includes(columnName)) {
      return formatDate(value);
    }

    return value?.trim();
  };

  return {
    data: process(),
    hasErrors: hasErrors,
    noMappingError: noMappingError,
    mappingMismatch: mappingMismatch,
    columnNames: tableHeaders,
    columnsWithErrors: _.uniq(columnsWithErrors),
  };
};

export default PharmacyClaimsEngine;

import React, { useState } from "react";
import _ from "lodash";
import BankAccountStatus from "/components/entities/components/BankAccountStatus";

const BankDetailsModal = ({ dashboard_path, entities }) => {
  const [open, setOpen] = useState(window.location.pathname == dashboard_path);

  const renderHeader = () => {
    return (
      <div className="modal__header">
        <div className="t__stack">
          <div className="t__stack__title t--lg">Bank Account Action Required</div>
          <div className="t__stack__subtitle">
            The following entities require action to complete the bank account setup.
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="modal__body">
        {entities.map((entity) => {
          return (
            <a href={entity.path} key={entity.id}>
              <div className="list__item">
                <div className="t__stack">
                  <div className="t__stack__subtitle">{entity.id_340b}</div>
                  <div className="t__stack__title t--sm">{entity.name}</div>
                </div>
                <div className="t__stack">
                  <div className="t__stack__subtitle t--right">Status</div>
                  <BankAccountStatus status={entity.status} />
                </div>
              </div>
            </a>
          );
        })}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer">
        <button className="button button--outlined" onClick={() => setOpen(false)}>
          Close
        </button>
      </div>
    );
  };

  const renderModal = () => {
    if (open) {
      return (
        <div
          className="modal__background"
          onClick={(e) => {
            e.target.className == "modal__background" && setOpen(false);
          }}
        >
          <div className="modal__container animated fadeInUp--sm">
            {renderHeader()}
            {renderBody()}
            {renderFooter()}
          </div>
        </div>
      );
    }
  };

  if (_.isEmpty(entities)) return null;

  return (
    <>
      <div onClick={() => setOpen(!open)} className="alert animated fadeInLeft">
        <div className="indicator indicator--orange indicator--pulse" />
        Bank Account Action Required
      </div>
      {renderModal()}
    </>
  );
};

export default BankDetailsModal;

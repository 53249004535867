import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
//import * as Turbo from "@hotwired/turbo"

import Table from "/components/shared/table";

function SubmissionsTable({
  data,
  isLoading,
  searchQuery,
  setSearchQuery,
  customerFilter,
  statusFilter,
  setNotice,
  handleRowClick,
  selectedRowIndex,
}) {
  const filterSetup = useMemo(
    () => [
      {
        columnName: "customer_name",
        filterValue: customerFilter,
      },
      {
        columnName: "upload_status",
        filterValue: statusFilter,
      },
    ],
    [customerFilter, statusFilter]
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return [
      columnHelper.accessor("name", {
        header: "Name",
        sorting: false,
        cell: (info) => <div className="t--fw-500">{info.getValue()}</div>,
      }),
      columnHelper.accessor("kind", {
        header: "Type",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("entities", {
        header: "Entities",
        cell: (info) => info.getValue(),
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
      columnHelper.accessor("rows", {
        header: "Rows",
        cell: (info) => info.getValue(),
        filterFn: "equalsString",
        sorting: false,
      }),
      columnHelper.accessor("quantity", {
        header: "Qty",
        cell: (info) => <>{info.getValue()} <span className="t--light">{info.row.original.units}</span></>,
        filterFn: "equalsString",
        sorting: false,
      }),
      columnHelper.accessor("submitted_by", {
        header: "Submitted",
        cell: (info) => <><div >{info.getValue()}</div><div className="t--light">{info.row.original.date}</div></>,
        enableGlobalFilter: false,
        sorting: false,
        sortDescFirst: true,
      }),
    ];
  });

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
        minRows={6}
      />
    </div>
  );
}

export default SubmissionsTable;

import React, { useEffect } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import TableLoadingSkeleton from "./table_loading_skeleton";

const Table = ({
  data,
  columns,
  tableOptions,
  onRowClick,
  selectedRowIndex,
  noDataMessage,
  isLoading,
  searchQuery,
  filterSetup,
  noResultsMessage,
  setNotice,
  minRows = 18,
}) => {
  const table = useReactTable({
    data,
    columns,
    ...tableOptions,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    if (!filterSetup) return;

    if (filterSetup) {
      table.getHeaderGroups().map((headerGroup) => {
        headerGroup.headers.map((header) => {
          filterSetup.forEach((filter) => {
            if (header.column.id === filter.columnName) {
              header.column.setFilterValue(filter.filterValue);
            }
          });
        });
      });
    }
  }, [filterSetup]);

  useEffect(() => {
    setTimeout(checkHasFilterResults, 100);
  }, [searchQuery, filterSetup]);

  const renderEmptyRows = ({ rows }) => {
    return [...Array(rows)].map((_, i) => (
      <tr className="no-hover" key={i}>
        {[...Array(columns.length)].map((_, i) => (
          <td key={i}></td>
        ))}
      </tr>
    ));
  };

  const checkHasFilterResults = () => {
    if (isLoading || !data?.length || table.getFilteredRowModel().rows.length) return;

    setNotice({
      kind: "warning",
      open: true,
      message: noResultsMessage,
    });
  };

  const renderTableRows = ({ columns, table, selectedRowIndex, onRowClick, isLoading }) => {
    if (isLoading) return <TableLoadingSkeleton columns={columns.length} rows={minRows} />;

    const tableRows = table.getRowModel().rows.map((row) => (
      <tr
        key={row.id}
        onClick={() => onRowClick(row)}
        className={`${selectedRowIndex === row.index ? "selected" : ""}`}
      >
        {row.getVisibleCells().map((cell) => (
          <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
        ))}
      </tr>
    ));

    return (
      <>
        {tableRows}
        {renderEmptyRows({ rows: Math.max(minRows - tableRows.length, 0) })}
      </>
    );
  };

  return (
    <table className="primary">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {renderTableRows({
          isLoading: isLoading,
          columns: columns,
          onRowClick: onRowClick,
          table: table,
          selectedRowIndex: selectedRowIndex,
        })}
      </tbody>
    </table>
  );
};

export default Table;

import React, { useState, useEffect } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from "@mui/lab";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { History as HistoryIcon, FiberManualRecord } from "@mui/icons-material";
import { fetchHistory } from "./pharmacy/http";
import { HistorySkeleton } from "./history_skeleton";
import { getVanityName } from "../submissions/utils/name_helper";

const Theme = createTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

export const History = (props) => {
  const [historyLines, setHistoryLines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await fetchHistory(props.history_path);
        setHistoryLines(response.data);
      } catch (error) {
        console.error({ error });
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [props.history_path]);

  const renderDifferences = (differences, not_considered) => {
    if (differences.length == 0)
      return (
        <div style={{ marginTop: 10 }}>
            <div className={`t--sm t--light t--center ${not_considered ? "t--grey" : ""}`}>No changes between submitted claims</div>
        </div>
      );
    return (
      <div style={{ marginTop: 10 }}>
        {differences.map((diff) => {
          return (
            <div
              className="flex notify__timeline p--8 t--sm"
              style={{ gap: 10 }}
              key={diff.column_name}
            >
              <div className="flex">
                {getVanityName(diff.column_name)} was changed
                from
                <div
                  className={`badge ${not_considered ? "badge--disabled" : ""}`}
                  style={{ margin: "0px 5px" }}
                >
                  {diff.previous_value ? diff.previous_value : <em>empty</em>}
                </div>{" "}
                to
                <div
                  className={`badge ${not_considered ? "badge--disabled" : ""}`}
                  style={{ margin: "0px 5px" }}
                >
                  {diff.current_value ? diff.current_value : <em>empty</em>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTimeline = () => {
    if (isLoading) {
      return (
        <table>
          <HistorySkeleton rows={3} />
        </table>
      );
    }
    return (
      <Timeline style={{ padding: 0 }}>
        {historyLines.map((history, index) => {
          return renderHistoryLine(
            history,
            index === historyLines.length - 1 ? null : historyLines[index + 1]
          );
        })}
      </Timeline>
    );
  };

  const renderHistoryLine = (history, next_history) => {
    return (
      <>
      <TimelineItem key={history.created_at}>
        <TimelineOppositeContent className={history.not_considered ? "t--grey" : ""}>
          <div className="t--sm">{history.date_submitted}</div>
          <div className="t--sm t--light">{history.submission_name}</div>
          <div className="t--sm t--light">{history.submitted_by}</div>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot className={`timeline ${history.not_considered ? "timeline--not-considered" : ""}`} />
          {history.first ? (
            ""
          ) : (
            <TimelineConnector
            className={`timeline ${history.not_considered ? "timeline--not-considered" : ""}`}
            />
          )}
        </TimelineSeparator>
        <TimelineContent  className={history.not_considered ? "t--grey" : ""}>
                {history.issues.map((reason) => (
                  <div
                    className={`badge ${
                      history.not_considered ? "badge--disabled" : ""
                    } mb-1`}
                    key={reason.value}
                  >
                    <div className="badge__indicator badge__indicator--red" />
                    {reason.label}
                  </div>
                ))}

        </TimelineContent>
      </TimelineItem>
      {!history.first &&
              renderDifferences(history.differences, history.not_considered)}
      {next_history
              ? renderMaxResubmissionMessage(
                  next_history.final,
                  next_history.conforming
                )
              : ""}
      </>
    );
  };

  const renderMaxResubmissionMessage = (showWarning, conforming) => {
    if (showWarning) {
      return (
        <div>
          <div
            className="flex"
            style={{ gap: 10, marginTop: 10 }}
          >
            <div className="material-symbols-outlined">arrow_upward</div>
            <div className="t--sm t--center">
              {conforming
                ? "A conforming claim has already been submitted. Resubmissions past this point are treated as duplicates and will not be considered."
                : "Maximum resubmissions reached. No claims considered after this point."}
            </div>
            <div className="material-symbols-outlined">arrow_upward</div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="section">Claim History</div>
      <div className="t--sm t--light t--blue t--right">
        {isLoading
          ? "Retrieving history..."
          : `${historyLines.length} submission${
              historyLines.length == 1 ? "" : "s"
            }`}
      </div>

      <ThemeProvider theme={Theme}>
        {renderTimeline()}
      </ThemeProvider>
    </>
  );
};

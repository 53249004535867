import _ from "lodash";

export const columnSchema = {
  contracted_entity_id: null,
  plan_id_qualifier: null,
  plan_id_code: null,
  plan_name: null,
  service_provider_id: null,
  product_service_id: null,
  billed_hcpcs_quantity: null,
  prescription_type: null,
  date_of_service: null,
  prescriber_id: null,
  claim_number: null,
  hcpcs_code: null,
  hcpcs_code_modifier_1: null,
};

export const columnDetails = [
  {
    name: "contracted_entity_id",
    title: "340B ID",
    description:
      "The HRSA assigned parent 340B ID of the entity that purchased the administered medication at the 340B price.",
    required: true,
    order: 12,
  },
  {
    name: "plan_id_qualifier",
    title: "Health Plan ID Qualifier",
    description: "The qualifier for the patient's health insurance plan ID.",
    required: false,
    order: 9,
  },
  {
    name: "plan_id_code",
    title: "Health Plan ID",
    description: "The identifier code of the patient's health insurance plan as assigned by the health insurer.",
    required: true,
    order: 10,
  },
  {
    name: "plan_name",
    title: "Health Plan",
    description:
      "Name of the patient's health insurance plan.  Examples include Medicare Part B, MediCal, Aetna POS, etc.",
    required: true,
    order: 11,
  },
  {
    name: "service_provider_id",
    title: "Service Provider ID",
    description:
      "The NPI of the healthcare entity where the patient received the medication administration.  For example, this could be the NPI of a hospital outpatient surgery center or the NPI of an outpatient infusion center.",
    required: true,
    order: 2,
  },
  {
    name: "product_service_id",
    title: "NDC-11",
    description: "The NDC-11 of the medication administered to the patient.",
    required: true,
    order: 4,
  },
  {
    name: "billed_hcpcs_quantity",
    title: "Quantity",
    description: "The quantity - as measured in billable units - of medication administered to the patient.",
    required: true,
    order: 5,
  },
  {
    name: "date_of_service",
    title: "Date of Service",
    description: "The date on which the medication was administered to the patient.",
    required: true,
    order: 3,
  },
  {
    name: "prescriber_id",
    title: "Physician ID",
    description: "The NPI of the physician that administered the medication to the patient.",
    required: true,
    order: 9,
  },

  {
    name: "claim_number",
    title: "Claim Number",
    description: "The claim number as assigned by the healthcare provider.",
    required: true,
    order: 1,
  },
  {
    name: "hcpcs_code",
    title: "HCPCS Code",
    description:
      "The five digit HCPCS code for separately payable medications.  This value may not exist for medications that are reimbursed as part of procedure (e.g. a blood thinner used as part of an outpatient procedure).",
    required: false,
    order: 7,
  },
  {
    name: "hcpcs_code_modifier_1",
    title: "HCPCS Code Modifier",
    description:
      "Modifier code associated with a separately payable medication with its own 5 digit HCPCS code.  This field can be used to indicate that the units are associated with drug wastage and are not duplicative of those units administered to the patient.",
    required: false,
    order: 8,
  },
];

export const tableHeaders = columnDetails.sort((a, b) => a.order - b.order).map((column) => column.name);
export const sortedColumnDetails = columnDetails.sort((a, b) => a.order - b.order);
export const required = columnDetails.filter((column) => column.required).map((column) => column.name);
export const notRequired = columnDetails.filter((column) => !column.required).map((column) => column.name);

export const isRequiredField = (column) => {
  return required.includes(column);
};

export class MedicalClaim {
  constructor() {
    this.hasErrors = false;

    this.data = {
      contracted_entity_id: null,
      plan_id_qualifier: null,
      plan_id_code: null,
      plan_name: null,
      service_provider_id: null,
      product_service_id: null,
      billed_hcpcs_quantity: null,
      prescription_type: null,
      date_of_service: null,
      prescriber_id: null,
      claim_number: null,
      hcpcs_code: null,
      hcpcs_code_modifier_1: null,
    };

    this.columns_with_errors = {
      contracted_entity_id: false,
      plan_id_qualifier: false,
      plan_id_code: false,
      plan_name: false,
      service_provider_id: false,
      product_service_id: false,
      billed_hcpcs_quantity: false,
      prescription_type: false,
      date_of_service: false,
      prescriber_id: false,
      claim_number: false,
      hcpcs_code: false,
      hcpcs_code_modifier_1: false,
    };
  }
}

import React from "react";

const Card = ({ header, subtitle, title, footer, selected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={selected ? "card card--selected" : "card"}
    >
      <div className="card__header t--sm">{header}</div>
      <div className="card__subtitle">{subtitle}</div>
      <div className="card__title">{title}</div>
      <div className="card__footer">{footer}</div>
    </div>
  );
};

export default Card;

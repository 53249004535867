import React, { useRef, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import ClaimDetailsTable from "./table";
import TableRowDetails from "./table_row_details";
import Notice from "/components/shared/Notice";
import Http from "../http";
import Filters from "/components/shared/filters";
import { stringify } from "qs";

const PharmacyEncounterReport = (props) => {
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const [filters, setFilters] = useState(props.filters);
  const [claims, setClaims] = useState([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const isMounted = useRef(false);

  useEffect(() => {
    setIsClaimsLoading(true);
    var params = stringify(filters);
    history.pushState(filters, "", "/claims/reports/pharmacy?" + params);
    Http.newFetchQuery(props.query_path, filters).then((res) => {
      setClaims(res.claims);
      setIsClaimsLoading(false);
    });
  }, [filters]);

  useEffect(() => {
    if (
      (isMounted.current && !claims) ||
      (isMounted.current && claims.length == 0)
    ) {
      setNotice({
        kind: "success",
        open: true,
        message: "The current selection and filters returned no results",
      });
    }

    isMounted.current = true;
  }, [claims]);


  const claimView = (tab) => {
    return (
      <div
        key={tab}
        className="tabs__view tabs__view--active"
        data-tab-name={tab}
      >
        <ClaimDetailsTable
          claims={claims}
          isLoading={isClaimsLoading}
          handleRowClick={setSelectedRow}
        />
      </div>
    );
  };

  return (
    <>
      <div className="body__head">
        <div className="body__head__title">Pharmacy Claims Report</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Filters kind="pharmacy" filterCallback={setFilters} defaultFilters={props.filters} filterLists={props.filterLists} />
        </div>
      </div>
      <div className="body__content">
        <div className="panel panel--pad panel--no-btm">
            {claimView()}
        </div>
        <div className="panel panel--info panel--pad panel--no-btm">
          <TableRowDetails row={selectedRow} />
        </div>
      </div>
      <Notice details={notice} />
    </>
  );
};

export default PharmacyEncounterReport;

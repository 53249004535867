import React, { useState } from "react";
import BankAccountStatus from "/components/entities/components/BankAccountStatus";
import BankAccountNotice from "/components/entities/components/BankAccountNotice";

const BankAccountList = ({ entity_details }) => {
  const [selectedEntity, setSelectedEntity] = useState(entity_details[0].id_340b);
  const selectedAccount = entity_details.find((detail) => detail.id_340b == selectedEntity);

  const renderAccountNumber = () => {
    const acc_number = selectedAccount.account_number;

    if (acc_number) {
      return `**** **** **** ${acc_number}`;
    }

    return "Pending";
  };

  const renderButton = () => {
    return (
      <a href={`/entities/${selectedEntity}`}>
        <div className="button button--sm button--outlined">View Entity</div>
      </a>
    );
  };

  const renderEntities = () => {
    return entity_details.map((detail) => {
      return (
        <div
          className={
            selectedEntity == detail.id_340b ? "list__item list__item--selected" : "list__item"
          }
          onClick={() => setSelectedEntity(detail.id_340b)}
        >
          <div className="t__stack">
            <div className="t__stack__subtitle">Entity ID</div>
            <div className="t__stack__title t--sm">{detail.id_340b}</div>
          </div>
          <div className="t__stack">
            <div className="t__stack__subtitle t--right">status</div>
            <BankAccountStatus status={detail.status} />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="bank_details__container">
      <BankAccountNotice status={selectedAccount.status} />
      <div className="bank_details__card">
        <div className="bank_details__card__header">{renderButton()}</div>
        <div className="bank_details__card__value">$0.00</div>
        <div className="bank_details__card__footer">
          <div className="t__stack">
            <div className="t__stack__subtitle t--white">Account</div>
            <div className="t__stack__title t--sm t--white">{renderAccountNumber()}</div>
          </div>
          <div className="t__stack">
            <div className="t__stack__subtitle t--white t--right">Entity</div>
            <div className="t__stack__title t--sm t--white">{selectedEntity}</div>
          </div>
        </div>
      </div>
      {renderEntities()}
    </div>
  );
};

export default BankAccountList;

// Reads attached Claim Files and returns data object

import XLSX from "xlsx";
import { selectMapping, getMapping } from "./data_processing";
import Attachment from "../schemas/attachment";

const FileProcessor = async ({
  id,
  file,
  data = null,
  kind,
  filterLists,
  columnMappings,
  salt,
  selectedMapping = null,
}) => {
  var data = data;
  if (!data) {
    const ab = await file.data.arrayBuffer();
    const wb = XLSX.read(ab);
    const ws = wb.Sheets[wb.SheetNames[0]];

    data = XLSX.utils.sheet_to_json(ws, {
      raw: false,
      blankrows: false,
      defval: "",
    });
  }

  const mapping = selectedMapping || selectMapping(columnMappings, data);

  filterLists.forEach((item) => {
    item.filter_list =
      typeof item.filter_list == "object" ? item.filter_list : JSON.parse(item.filter_list);
  });

  return new Attachment(id, file, kind, data, mapping, filterLists, salt);
};

export default FileProcessor;

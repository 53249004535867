import React, { useState, useEffect } from "react";
import Filters from "/components/shared/filters";
import Http from "../http";
import { stringify } from "qs";
import BalancesTable from "./tables/balances_table";
import PackagesTable from "./tables/packages_table";

const PurchaseReport = (props) => {
  const [selected, setSelected] = useState(null);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const [filters, setFilters] = useState(props.filters);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    var params = stringify(filters);
    history.pushState(filters, "", "/accounts/balance?" + params);
    Http.newFetchQuery(props.query_path, filters).then((res) => {
      setData(res.data);
      setIsLoading(false);
    });
  }, [filters]);

  const renderAccount = () => {
    return (
      <>
        <div className="t--lg">Balances by Account</div>
        <BalancesTable data={data} handleRowClick={(event) => setSelected(event.original)} />
      </>
    );
  };

  const renderPackages = () => {
    if (selected == null) {
      return (
        <>
          <div className="section">Packages</div>
          <div className="t--sm t--light">Please select an account</div>
        </>
      );
    }
    return (
      <>
        <div className="section">Packages</div>
        <PackagesTable data={selected.packages} />
      </>
    );
  };

  const renderClaims = () => {
    if (selected == null) {
      return (
        <>
          <h3>Claims</h3>
          <p>Please select an account</p>
        </>
      );
    }
    return (
      <>
        <h3>Claims</h3>
        <table className="report__table">
          <tr>
            <th>Claim ID</th>
            <th>Quantity</th>
            <th>Status</th>
            <th>Amount</th>
          </tr>
          {selected.claims.map((payment, index) => {
            return (
              <tr className="report__table--row" key={index}>
                <td>{payment.claim_id.substring(0, 5)}</td>
                <td>{payment.quantity}</td>
                <td>{payment.status}</td>
                <td>{payment.amount}</td>
              </tr>
            );
          })}
        </table>
      </>
    );
  };

  return (
    <>
      <div className="body__head">
        <div className="body__head__title">Balance Report</div>
        <Filters
          filterCallback={setFilters}
          defaultFilters={props.filters}
          filterLists={props.filterLists}
          disabledFilters={{ date_range: true, submission: true }}
        />
      </div>
      <div className="body__content">
        <div className="panel panel--pad panel--no-btm">{renderAccount()}</div>
        <div className="panel panel--pad panel--info panel--no-btm">{renderPackages()}</div>
      </div>
    </>
  );
};

export default PurchaseReport;

import React, { useState, useEffect } from "react";
import verifyImage from "~/images/verify_account_illustration.png";

const ConfirmationModal = (props) => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmitting = () => {
    setSubmitting(true);

    if (submitting === false) {
      props.action();
    }
  };

  const renderTitle = () => {
    if (props.title) {
      return props.title;
    } else {
      return null;
    }
  };

  const renderMessage = () => {
    if (props.message) {
      return props.message;
    } else {
      return "Please confirm you have verified column mappings and selected data for submission.";
    }
  };

  const renderLoader = () => {
    if (submitting) {
      return <div className="loading-indicator"></div>;
    }
  };

  const renderModal = () => {
    if (props.displayModal) {
      return (
        <div className="modal__background">
          <div className="modal__container animated fadeInUp--sm">
            <div className="modal__header">
              <div className="t--lg">Please Confirm</div>
            </div>

            <div className="modal__body">
              <div className="container container--justify-center">
                <img src={verifyImage} width={220} style={{ marginBottom: 30 }} />
              </div>
              {/* {renderTitle()} */}
              <div className="t--subtitle">{renderMessage()}</div>
            </div>
            <div className="modal__footer">
              <button
                className="button button--label"
                onClick={() => {
                  props.setModalState(false);
                }}
              >
                Cancel
              </button>
              <button
                className="button"
                id="confirmButton"
                onClick={() => {
                  handleSubmitting();
                }}
              >
                {renderLoader()}
                Confirm Submission
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return renderModal();
};
export default ConfirmationModal;

import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Billboard from "./reset-billboard";
import {
  hasAtLeastOneLower,
  hasAtLeastOneUpper,
  hasAtLeastOneNumber,
  hasAtLeastOneSpecial,
  hasAtLeastTwelveChars,
  hasMatchingPasswords,
  isValidPassword
} from '../utils/PasswordLength';

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const ResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordIsMasked, setPasswordIsMasked] = useState(true);
  const [confirmPasswordIsMasked, setConfirmPasswordIsMasked] = useState(true);

  const enableSubmit = () => {
    return isValidPassword(password) && isValidPassword(confirmPassword) && hasMatchingPasswords(password, confirmPassword);
  };

  const renderForm = () => {
    return (
      <div className="form__registration animated fadeInUp--sm">
        <form method="post" action={`/password_resets/${props.token}`} className="authentication__form">
          <div className="form__group">
            <div className="form__section">Reset your Password</div>
            <p className="form__group__note">Enter and confirm your new password.</p>

            <CustomTextField
              placeholder="Password"
              variant="outlined"
              id="password"
              fullWidth
              required
              autoFocus
              size="small"
              name="user[password]"
              type={passwordIsMasked ? "password" : "text"}
              error={password ? !isValidPassword(password) : false}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ cursor: "pointer" }} onClick={() => setPasswordIsMasked(!passwordIsMasked)}>
                      <span
                        style={{
                          color: "#718291",
                          fontSize: 14,
                          marginRight: 10,
                          verticalAlign: "top",
                          lineHeight: "20px",
                        }}
                      >
                        {passwordIsMasked ? "show" : "hide"}
                      </span>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="form__group">
            <CustomTextField
              placeholder="Confirm Password"
              variant="outlined"
              id="confirm_password"
              fullWidth
              required
              size="small"
              name="user[confirm_password]"
              type={confirmPasswordIsMasked ? "password" : "text"}
              error={confirmPassword ? !isValidPassword(confirmPassword) && !hasMatchingPasswords(password, confirmPassword) : false}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => setConfirmPasswordIsMasked(!confirmPasswordIsMasked)}
                    >
                      <span
                        style={{
                          color: "#718291",
                          fontSize: 14,
                          marginRight: 10,
                          verticalAlign: "top",
                          lineHeight: "20px",
                        }}
                      >
                        {confirmPasswordIsMasked ? "show" : "hide"}
                      </span>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            <input hidden name="token" value={props.token} />
            <input
              type="hidden"
              name="authenticity_token"
              value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
            />
            <div className="form__group__note" style={{ display: "flex", gap: 50 }}>
              <div>
                <ul>
                  <li className={hasAtLeastOneLower(password) ? "success" : null}>One lowercase character</li>
                  <li className={hasAtLeastOneUpper(password) ? "success" : null}>One uppercase character</li>
                  <li className={hasAtLeastOneNumber(password) ? "success" : null}>One number</li>
                </ul>  
              </div>
              <div>
                <ul>
                  <li className={hasAtLeastOneSpecial(password) ? "success" : null}>One special character</li>
                  <li className={hasAtLeastTwelveChars(password) ? "success" : null}>12 characters minimum</li>
                  <li className={hasMatchingPasswords(password, confirmPassword) ? "success" : null}>Matching passwords</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="form__group">
            <button className={`btn btn--fw ${enableSubmit() ? '' : 'btn--disabled'}`} type="submit" disabled={!enableSubmit()}>
              Reset Password
            </button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="row">
      <div className="col-6 bg-light-gray">
        <Billboard />
      </div>
      <div className="col-6">{renderForm()}</div>
    </div>
  );
};

export default ResetPassword;

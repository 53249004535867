import React, { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "/components/shared/table";

function AccountsTable({ data, isLoading, searchQuery, setSearchQuery, getIndicatorColor, setNotice, handleRowClick, selectedRowIndex}) {

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

  return [
    columnHelper.accessor("id", {
      header: "ID",
      sorting: false,
      cell: (info) => <div className="t--fw-500">{info.getValue().substring(0,8)}</div>,
    }),
    columnHelper.accessor("invoice_number", {
      header: "Invoice Number",
      sorting: false,
      cell: (info) => <div className="t--fw-500">{info.getValue()}</div>,
    }),
    columnHelper.accessor("package_number", {
      header: "Package Number",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
    columnHelper.accessor("invoice_date", {
      header: "Invoice Date",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: (info) =>         
      <div className="badge">
        <div className={`badge__indicator badge__indicator--${getIndicatorColor(info.getValue())}`} />
        {info.getValue()}
      </div>,
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
    columnHelper.accessor("units", {
      header: "Units",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
    columnHelper.accessor("date", {
      header: "Date",
      cell: (info) => info.getValue(),
      enableGlobalFilter: false,
      sorting: false,
      sortDescFirst: true,
    }),
  ];
});

  const tableOptions = {
    state: { globalFilter: searchQuery },
    onGlobalFilterChange: setSearchQuery,
  };

  return (
    <div className="table--scroll">
      <Table
        data={data}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleRowClick}
        isLoading={isLoading}
        enableHover={true}
        searchQuery={searchQuery}
        //filterSetup={filterSetup}
        noResultsMessage="The current search and filter criteria returned no results"
        setNotice={setNotice}
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
      />
    </div>
  );
}

export default AccountsTable;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormInput from "/components/shared/FormInput";
import SubmitButton from "~/components/shared/SubmitButton";

const VerifyDepositsModal = (props) => {
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
 const handleSubmit = () => {
   setIsSubmitting(true);
 };

  const {
    register,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const renderHeader = () => {
    return (
      <div className="modal__header">
        <div className="t__stack">
          <div className="t__stack__title t--lg">Verify Deposit Transactions</div>
          <div className="t__stack__subtitle">
            To activate your Bank Account please verify the two micro deposit transactions sent to
            your account.
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="modal__body">
        <FormInput
          label="Deposit Transaction One"
          placeholder="0.00"
          name="deposit[transaction_one]"
          register={register}
          registerOptions={{
            required: "Field is required",
            pattern: {
              value: /^\d?\.?\d{0,2}$/,
              message: "Please enter a number (no dollar signs)",
            },
          }}
          errors={errors}
        />
        <FormInput
          label="Deposit Transaction Two"
          placeholder="0.00"
          name="deposit[transaction_two]"
          register={register}
          registerOptions={{
            required: "Field is required",
            pattern: {
              value: /^\d?\.?\d{0,2}$/,
              message: "Please enter a number (no dollar signs)",
            },
          }}
          errors={errors}
        />
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className="modal__footer">
        <button className="button button--label" onClick={() => setOpen(false)}>
          Cancel
        </button>
        <SubmitButton title="Verify and activate" className="button" isDisabled={!isValid || isSubmitting} />
      </div>
    );
  };

  const renderModal = () => {
    if (open) {
      return (
        <div
          className="modal__background"
          onClick={(e) => {
            e.target.className == "modal__background" && setOpen(false);
          }}
        >
          <div className="modal__container animated fadeInUp--sm">
            <form
              method="post"
              className="form__registration animated fadeInUp--sm"
              encType="multipart/form-data"
              action={`/entities/${props.id_340b}/bank_details/${props.bank_account_id}/verify`}
              onSubmit={handleSubmit}
            >
              <input
                type="hidden"
                name="authenticity_token"
                value={document.querySelector("[name=csrf-token]").content}
              />
              {renderHeader()}
              {renderBody()}
              {renderFooter()}
            </form>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="button button--sm button--outlined" onClick={() => setOpen(!open)}>
        Verify Deposits
      </div>
      {renderModal()}
    </>
  );
};

export default VerifyDepositsModal;

import React, { useState } from "react";
import Tabs from "/components/shared/tabs";
import PharmacyClaimsTable from "./tables/pharmacy_claims_table";
import MedicalClaimsTable from "./tables/medical_claims_table";
import Notice from "/components/shared/Notice";

const Index = (props) => {
  const [selectedTab, setSelectedTab] = useState(props.kind == "medical" ? 2 : 1);
  const [selectedRow, setSelectedRow] = useState(props.pharmacy_submissions ? props.pharmacy_submissions[0] : null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(1);

  const notice = {
    kind: "success",
    open: props.notice ? true : false,
    message: props.notice ?? "",
  };

  const tabs = [
    { id: 1, label: "Pharmacy Claims" },
    { id: 2, label: "Medical Claims" },
  ];

  const getIndicatorColor = (status) => {
    switch (status?.toLowerCase()) {
      case "complete":
        return "green";
      default:
        return "blue";
    }
  };

  const handleRowClick = (row) => {
    setSelectedRow(row.original);
    setSelectedRowIndex(row.index);
  };

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case 1:
        return (
          <PharmacyClaimsTable
            data={props.pharmacy_submissions}
            handleRowClick={handleRowClick}
            selectedRowIndex={selectedRowIndex}
            getIndicatorColor={getIndicatorColor}
          />
        );
      case 2:
        return (
          <MedicalClaimsTable
            data={props.medical_submissions}
            handleRowClick={handleRowClick}
            selectedRowIndex={selectedRowIndex}
            getIndicatorColor={getIndicatorColor}
          />
        );
      default:
        return <PharmacyClaimsTable data={props.pharmacy_submissions} />;
    }
  };

  const renderIssues = () => {
    return selectedRow?.issues?.map((issue, index) => (
      <div className="list__item list__item--disable-hover">
        <div className="container container--align-center">
          <div className="indicator indicator--red"></div>
          <div className="t--subtitle t--sm">Issue</div>
        </div>
        <div className="t--sm">{issue.label}</div>
      </div>
    ));
  };

  return (
    <>
      <div className="panel panel--pad panel--no-btm">
        <Tabs items={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        {renderSelectedTab()}
      </div>
      <div className="panel panel--info panel--pad panel--no-btm">
        <div className="section">Submission Details</div>
        <div className="list__item list__item--disable-hover list__item--gap-sm">
          <div className="t__stack">
            <div className="t__stack__subtitle">Submission Name</div>
            <div className="t__stack__title t--sm">{selectedRow?.name}</div>
          </div>
          <a href={selectedRow?.link}>
            <div className="button">View Submission</div>
          </a>
        </div>
        <div className="list__item list__item--disable-hover list__item--gap-sm">
          <div className="t__stack">
            <div className="t__stack__subtitle">Submission By</div>
            <div className="t__stack__title t--sm">{selectedRow?.submitted_by}</div>
          </div>
          <div className="avatar avatar--sm">{selectedRow?.submitted_by_initials}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Submitted Date</div>
          <div className="t--sm">{selectedRow?.date}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Row Count</div>
          <div className="t--sm">{selectedRow?.row_count}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Status</div>
          <div className="t--sm">{selectedRow?.status}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Conforming %</div>
          <div className="t--sm">{selectedRow?.conforming_percentage}</div>
        </div>

        <div className="section">Issues</div>
        {renderIssues()}
      </div>
      <Notice details={notice} />
    </>
  );
};

export default Index;

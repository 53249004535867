import React, { useState } from "react";
import { Controller } from "react-hook-form";

const FileUpload = ({ name, control, accept = "" }) => {
  const [file, setFile] = useState();

  const handleFile = (e) => {
    setFile({ name: e.target.files[0].name, type: e.target.files[0].type });
  };

  const renderFile = () => {
    if (file) {
      return (
        <>
          <div className="file_upload__icon">
            <span className="material-symbols-outlined">description</span>
          </div>
          <div className="file_upload__details">
            <div className="file_upload__details__title">{file?.name}</div>
            <div className="file_upload__details__subtitle">{file?.type}</div>
          </div>
        </>
      );
    }

    return <div className="file_upload__note">Please select a file to upload</div>;
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: "This file is required" }}
      render={({ field: { onChange, ref }, fieldState }) => {
        return (
          <>
            <div className="file_upload">
              {renderFile()}
              <button
                type="button"
                className="button button--outlined"
                onClick={() => document.getElementById(name).click()}
              >
                <span className="material-symbols-outlined">post_add</span>
                Select File
              </button>
              <div>
                <input
                  id={name}
                  name={name}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(event) => {
                    onChange(event.target.files?.[0]);
                    handleFile(event);
                  }}
                  ref={ref}
                  accept={accept}
                />
              </div>
            </div>
            <div className="form__error--message">{fieldState.error?.message}</div>
          </>
        );
      }}
    />
  );
};

export default FileUpload;

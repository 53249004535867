import React, { useState } from "react";
import Billboard from "./billboard";
import MobileForm from "./forms/mobile_form";
import EmailForm from "./forms/email_form";
import AppForm from "./forms/app_form";
import Notice from "/components/shared/Notice";

const MfaSelect = (props) => {
  const [notice, setNotice] = useState({
    kind: "success",
    open: false,
    message: null,
  });

  const [selectedOption, setSelectedOption] = useState("Mobile");
  const options = [
    { name: "Mobile", label: "Delivery via a text message." },
    { name: "Email", label: "Delivery via an email message." },
    { name: "Authenticator App", label: "Delivery via application of choice." },
  ];

  const renderSelectedIndicator = (option) => {
    if (option.name == selectedOption) {
      return <div className="indicator__selected"></div>;
    }
  };

  const renderOptions = () => {
    return options.map((option) => {
      return (
        <div key={option.name} className="form__group">
          <div
            className={
              selectedOption == option.name
                ? "list__item list__item--selected list__item--no-mgn"
                : "list__item list__item--no-mgn"
            }
            onClick={() => {
              setSelectedOption(option.name);
            }}
          >
            <strong>
              {renderSelectedIndicator(option)} {option.name}
            </strong>
            {option.label}
          </div>
          {renderSelectForm(option.name)}
        </div>
      );
    });
  };

  const renderSelectForm = (option) => {
    switch (selectedOption) {
      case "Mobile":
        if (selectedOption === option) {
          return <MobileForm setNotice={setNotice} />;
        }
      case "Email":
        if (selectedOption === option) {
          return <EmailForm setNotice={setNotice} />;
        }
      case "Authenticator App":
        if (selectedOption === option) {
          return (
            <AppForm setNotice={setNotice} qr_code={props.qr_code} mfa_secret={props.mfa_secret} />
          );
        }
    }
  };

  const renderForm = () => {
    return (
      <div className="form__registration animated fadeInUp--sm">
        <div className="form__section mt--2">Select Multi-Factor Authentication Type</div>
        <p className="form__group__note">
          You are required to select an MFA method of your choice. Once this preference is set, you
          will receive your MFA code via the method of your choice going forward.
        </p>
        {renderOptions()}
      </div>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-6 bg-light-gray">
          <Billboard kind={"verify"} />
        </div>
        <div className="col-6">{renderForm()}</div>
      </div>
      <Notice details={notice} />
    </>
  );
};

export default MfaSelect;

import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import { Add } from "@mui/icons-material";
import FieldMappingGroup from "./field_mapping_group";
import Overlay from "./overlay";

const FieldMappings = (props) => {
  const columnDetails = _.cloneDeep(props.columnDetails);
  const scrollContainer = useRef();
  const newMappingRef = useRef();
  const [displayTutorial, setDisplayTutorial] = useState(false);

  const orderedFields = columnDetails.sort((a, b) =>
    a.title.localeCompare(b.title, undefined, {
      numeric: true,
      sensitivity: "base",
    })
  ); // This does a natural sort so 10 will come after 2
  const newMappingPosition = props.mappings.length + 1;

  // Scrolls new mapping into view
  useEffect(() => {
    if (newMappingRef.current) {
      scrollNewMappingIntoView();
    }
  }, [props.newMapping]);

  const scrollNewMappingIntoView = () => {
    if (scrollContainer.current && newMappingRef.current) {
      const mappingPosition =
        newMappingRef.current.offsetLeft - scrollContainer.current.offsetLeft;

      scrollContainer.current.scrollTo({
        left: mappingPosition,
        behavior: "smooth",
      });
    }
  };

  const createNewFieldMappingGroup = () => {
    props.setNewMapping(true);
    props.setEditState({ ...props.editState, position: newMappingPosition });
  };

  const renderNewFieldMappingsBtn = () => {
    const editStateHasMappings = Object.values(props.editState.mappings);
    const isEditStateMapping = editStateHasMappings.some(
      (item) => item !== null
    );

    const editorOpen = !!props.editState.position;
    return (
      <button
        className={`button button--outlined ${
          editorOpen || isEditStateMapping ? "btn--disabled" : "isDisabled"
        }`}
        onClick={createNewFieldMappingGroup}
        disabled={editorOpen || isEditStateMapping || props.isSaving}
      >
        <span className="material-symbols-outlined">add</span>
        <span>Create a New mapping</span>
      </button>
    );
  };

  const saveMapping = () => {
    props.updateFieldMapping(props.editState);
    props.setEditState({ id: null, position: null, name: "", mappings: {} });
  };

  const removeMapping = (fieldName) => {
    props.setEditState({
      ...props.editState,
      mappings: { ...props.editState.mappings, [fieldName]: null },
    });
  };

  const saveDisabled = () => {
    const mappingValues = _.values(props.editState.mappings);

    return (
      mappingValues.some((value) => !value) ||
      mappingValues.length < columnDetails.length
    );
  };

  const createMapping = () => {
    props.createFieldMapping(props.editState);
    props.setEditState({ id: null, position: null, name: "", mappings: {} });
    props.setNewMapping(false);
  };

  const closeNewMapping = () => {
    props.setNewMapping(false);
    props.setEditState({ id: null, position: null, name: "", mappings: {} });
  };

  const renderMappingGroup = (mapping, index) => {
    return (
      <FieldMappingGroup
        key={index}
        position={index}
        mapping={mapping}
        orderedFields={orderedFields}
        inEditMode={props.editState.position === index}
        disabled={
          props.editState.position !== null &&
          props.editState.position !== index
        }
        saveMapping={saveMapping}
        deleteFieldMappingGroup={props.deleteFieldMappingGroup}
        editState={props.editState}
        setEditState={props.setEditState}
        removeMapping={removeMapping}
        isDeleting={props.isDeleting}
        notRequired={props.notRequired}
      />
    );
  };

  const renderMappings = () => {
    return props.mappings.map((mapping, index) =>
      renderMappingGroup(mapping, index)
    );
  };

  const closeTutorial = () => {
    setDisplayTutorial(false);
  };

  return (
    <div className="pill__container">
      <div className="mappings__container">
        <div className="mappings__header">{renderNewFieldMappingsBtn()}</div>
          <div className="mappings__groups-container" ref={scrollContainer}>
            {renderMappings()}
            <Overlay display={props.isSaving} parentRef={scrollContainer} />

            {props.newMapping && (
              <FieldMappingGroup
                key="temp_mapping"
                position={newMappingPosition}
                mapping={{ name: "", mappings: {} }}
                orderedFields={orderedFields}
                inEditMode={true}
                disabled={false}
                saveMapping={createMapping}
                closeNewMapping={closeNewMapping}
                editState={props.editState}
                setEditState={props.setEditState}
                removeMapping={removeMapping}
                ref={newMappingRef}
                notRequired={props.notRequired}
              />
            )}
          </div>
      </div>
    </div>
  );
};

export default FieldMappings;

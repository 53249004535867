import React from "react";
import BillboardSupportFooter from "~/components/shared/BillboardSupportFooter";
import BillboardStepItem from "~/components/shared/BillboardStepItem";

const Billboard = () => {
  return (
    <div className="billboard__container animated fadeInDown--sm">
      <div className="t--subtitle">Beacon: Illuminating Pharmaceutical Pricing</div>
      <div className="t--lg">Exception Request</div>
      <p>Begin the exception process by providing your contact details, selecting the appropriate manufacturer and uploading the necessary documentation to support the exception request.</p>
      <div>
        <div className="mt--3 t--bold">Steps</div>
        <BillboardStepItem num={1} title="Contact Details" subtitle="Provide your contact details" />
        <BillboardStepItem
          num={2}
          title="Select the Manufacturer"
          subtitle="Select the manufacturer for which the exception request applies to"
        />
        <BillboardStepItem
          num={3}
          title="Provide Documentation"
          subtitle="Upload the documentation necessary to support the exception request" />
      </div>
      <p>Once submitted, you will be notified by Beacon Support if any additional information is needed to facilitate the exception request.</p>
      <BillboardSupportFooter />
    </div>
  );
};

export default Billboard;

import React, { useState, useEffect } from "react";
import Filters from "/components/shared/filters";
import Http from "../http";
import { stringify } from "qs";
import InvoiceLinesTable from "./tables/invoice_lines_table";
import PackagesTable from "./tables/packages_table";
import { Illustration } from "/components/images/svgs";
import _ from "lodash";

const PurchaseReport = (props) => {
  const [selectedLineItem, setSelectedLineItem] = useState(null);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const [filters, setFilters] = useState(props.filters);
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    var params = stringify(filters);
    history.pushState(filters, "", "/purchases/reports?" + params);
    Http.newFetchQuery(props.query_path, filters).then((res) => {
      setInvoices(res.purchases);
      setIsLoading(false);
    });
  }, [filters]);

  const renderInvoice = (invoice) => {
    return (
      <>
        <InvoiceLinesTable
          data={invoice}
          isLoading={isLoading}
          handleRowClick={(event) => setSelectedLineItem(event.original)}
          getIndicatorColor={getIndicatorColor}
        />
      </>
    );
  };

  const getIndicatorColor = (status) => {
    switch (status?.toLowerCase()) {
      case "fully matched":
        return "green";
      case "partially matched":
        return "blue";
      case "not matched":
        return "grey";
      default:
        return "blue";
    }
  };

  const renderIssues = (issues) => {
    if (_.isEmpty(issues)) {
      return (
        <div className="list__item list__item--disable-hover">
          <div className="t--sm t--italic t--subtitle">No issues found</div>
        </div>
      );
    }
    return issues?.map((issue, index) => (
      <div className="list__item list__item--disable-hover">
        <div className="container container--align-center">
          <div className="indicator indicator--red"></div>
          <div className="t--subtitle t--sm">Issue</div>
        </div>
        <div className="t--sm">{issue.label}</div>
      </div>
    ));
  };

  const renderDetails = (line) => {
    if (!line) {
      return (
        <>
          <div className="section">Details</div>
          <div className="flex justify-center flex-col" style={{ height: "calc(100vh - 340px)" }}>
            <Illustration width={178} />
            <div className="t--sm t--upcase t--center mt--8 mb--8">No Purchase Selected</div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="section">Details</div>
        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Invoice Number</div>
          <div className="t--sm">{line.invoice_number}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Invoice Date</div>
          <div className="t--sm">{line.invoice_date}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">NDC</div>
          <div className="t--sm">{line.ndc}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Ship-To</div>
          <div className="t--sm">{line.service_provider}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Wholesaler</div>
          <div className="t--sm">{line.wholesaler}</div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Filled</div>
          <div className="t__stack">
            <div className="t--sm t--right">{`${line.filled_packages} of ${line.package_quantity}`}</div>
            <div className="t--sm t--light t--right">{line.filled_percentage}</div>
          </div>
        </div>

        <div className="list__item list__item--disable-hover">
          <div className="t--subtitle t--sm">Status</div>
          <div className="t--sm">{line.status}</div>
        </div>

        <div className="section">Issues</div>
        {renderIssues(line.issues)}

        {renderPackagesTable(line)}
      </>
    );
  };

  const renderPackagesTable = (line) => {
    if (line.package_quantity < 0) {
      return (
        <>
          <div className="section">Reversed Packages</div>
          <PackagesTable data={line.reversed_packages} />
        </>
      );
    }

    return (
      <>
        <div className="section">Packages</div>
        <PackagesTable data={line.packages} />
      </>
    );
  }


  return (
    <>
      <div className="body__head">
        <div className="body__head__title">Purchase Report</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Filters
            kind="invoice"
            filterCallback={setFilters}
            defaultFilters={props.filters}
            filterLists={props.filterLists}
          />
        </div>
      </div>
      <div className="body__content body__content">
          <div className="panel panel--pad panel--no-btm">{renderInvoice(invoices)}</div>
          <div className="panel panel--info panel--pad panel--no-btm">{renderDetails(selectedLineItem)}</div>
        </div>
    </>
  );
};

export default PurchaseReport;

import _, { map } from "lodash";
import { SHA3 } from "sha3";
import { isValidDate } from "../validators/data_validations";

// Helper functions to standardize data formatting.
// All listed Functions are intended to be utilized by any claim type.

// Formats date value to yyyy-mm-dd
export const formatDate = (value) => {
  // return early if value is not a date
  if (isNaN(Date.parse(value))) return;

  if (!isValidDate(value)) {
    return value;
  }

  // JS dates are off by 1 day when yyyy-mm-dd
  // convert date format to yyyy/mm/dd to ensure correct day date
  const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
  if (regex.test(value)) {
    value = _.replace(value, /-/g, "/");
  }

  // use US date format mm-dd-yyyy
  var dateString = new Date(value).toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  // split formatted date
  var dateParts = dateString.split("/");

  if (dateParts.length === 3) {
    // convert to ISOString manually which will exclude timezone
    // ensure formatted date returned is yyyy-mm-dd
    return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
  } else {
    // invalid date format
    return false;
  }
};

// Strip leading zeros from value
export const removeLeadingZeros = (value) => {
  return value.replace(/^0+/, "");
};

// Formats Rx Number - removes fill count eg. 12345678-01 => 12345678
export const normalizeRxNumber = (value) => {
  if (typeof value !== "string") {
    return;
  }

  var rx_number = value.split("-")[0];

  return removeLeadingZeros(rx_number);
};

// salt is 32 bit hexadecimal - 2 char per byte(64)
export const generateSecureHash = (value, salt) => {
  // salt is 32 bit hexadecimal - 2 char per byte(64)

  // return early if no value, salt or incorrect salt character length
  if (!value || !salt || salt.length != 64) return;

  var hash = new SHA3(256).update(value + salt);

  return hash.digest("hex");
};

// truncate string and append ellipses
export const truncateString = (str, num) => {
  if (!str || str.length <= num) {
    return str;
  }

  return str.slice(0, num) + "...";
};

export const formatFilterKey = (filterKey) => {
  switch (filterKey) {
    case "ndc":
      return "NDC";
    case "hcpcs_code":
      return "HCPCS Codes";
  }
};

export const formatEntityId = (entityId) => {
  if (!entityId) return;
  
  var val = entityId.toUpperCase();

  const prefixList = ["RRC", "SCH", "CAN", "PED", "CAH"];

  // clean and append parent identifier
  for (let item of prefixList) {
    if (val.startsWith(item)) {
      return (val.substring(0, 9) + "-00").replaceAll("--", "-");
    }
  }

  // clean and remove any child identifier
  if (val.startsWith("DSH")) {
    return val.substring(0, 9);
  }

  // insert missing hyphen
  if (val.startsWith("CHC") && !strings.Contains(val, "-") && len(val) == 11) {
    return val.substring(0, 9) + "-" + val.substring(9);
  }

  // none of the above rules matched, so return the original string
  return val;
};
